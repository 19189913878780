"use strict";

class ImageAudioController {

    constructor(player) {
        this.player = player;
        this.defaultVoiceType = 'male_level_1';
        this.linksAndSpecialDelay = 1000;
        this.selectedVoiceType = null;

        this.playHandle = null;
        this.playHandles = [];
        this.playLocked = false;
    }

    setSelectedVoiceType(type) {
        this.selectedVoiceType = type;
    }

    run() {
        ImageAudioController.initializeHoverTooltips();
        this.tagEachItem();
        this.listenToEvents();
    }

    /**
     * add a tag to each audio item so they can be later referenced
     * especially for play handles
     */
    tagEachItem() {
        let audioItems = document.querySelectorAll('.image-audio');
        for (let i = 0; i < audioItems.length; i++) {
            audioItems[i].setAttribute('data-audio-tag', i.toString());
        }
    }

    static isLinkAndSpecial(el) {
        return (el.parentNode.nodeName.toLowerCase() === 'a'
            || el.classList.contains('image-hover')
            || el.getAttribute('id') === 'settings_button');
    }

    static isHoverElement(el) {
        return (el.hasAttribute('data-audio-text') && el.hasAttribute('data-play-on-hover'));
    }

    play(text, tag) {
        let self = this;
        console.log('Requesting to play: ' + text);

        let doPlay = function(text) {
            return self.player.playText(text, {
                voiceType: LocalStorageWrapper.get('selectedVoice') || self.defaultVoiceType,
                onStarted: function(e) {
                    if (typeof tag !== 'undefined') {
                        console.log('Started on tag: ' + tag);
                    }
                    self.playLocked = true;
                },
                onEnded: function(e) {
                    if (typeof tag !== 'undefined') {
                        console.log('Ended on tag: ' + tag);
                    } else {
                        console.log('Play ended');
                    }

                    self.playLocked = false;

                    if (typeof tag !== 'undefined') {
                        self.playHandles[parseInt(tag)] = null;
                    } else {
                        self.playHandle = null;
                    }
                }
            });
        };

        if (typeof tag !== 'undefined') {
            this.playHandles[parseInt(tag)] = doPlay(text);
        } else {
            this.playHandle = doPlay(text);
        }

        if (this.playHandle || this.playHandles[parseInt(tag)]) {
            console.log('Handle returned.');
        } else {
            console.log('OOPS: No handle was returned');
        }
    }

    listenToEvents() {
        let self = this;

        document.body.addEventListener('click', function (event) {
            if (event.target.classList.contains('image-audio')
                && event.target.nodeName.toLocaleLowerCase() === 'img'
                && event.target.hasAttribute('data-audio-text')
                && event.target.hasAttribute('data-play-on-click'))
            {
                if (!self.playLocked && !self.player.locked) {
                    self.play(event.target.getAttribute('data-audio-text'));
                }
                else {
                    if (self.playHandle) {
                        console.log('Stopping via handle');
                        self.playHandle.stop();
                        self.playHandle = null;
                    }
                    else if (typeof self.player.stop !== 'undefined') {
                        console.log('Stopping via pcuesAudioApi.stop()');
                        self.player.stop();
                    }

                    self.playLocked = false;
                }
            }
        });

        let timer = null;

        document.body.addEventListener('mouseover', function(event) {
            if (ImageAudioController.isHoverElement(event.target)) {
                if (LocalStorageWrapper.get('hoverState') === "true") {
                    if (!self.playHandle && !self.player.locked) {
                        if (ImageAudioController.isLinkAndSpecial(event.target)) {
                            timer = setTimeout(function() {
                                self.play(event.target.getAttribute('data-audio-text'))
                            }, self.linksAndSpecialDelay);
                        }
                        else {
                            self.play(event.target.getAttribute('data-audio-text'), event.target.getAttribute('data-audio-tag'));
                        }
                    }
                }
            }
        });

        document.body.addEventListener('mouseout', function(event) {
            if (ImageAudioController.isHoverElement(event.target)) {
                console.log('Stopping audio');
                if (ImageAudioController.isLinkAndSpecial(event.target)) {
                    if (timer) {
                        clearTimeout(timer);
                        timer = null;
                    }
                }

                if (event.target.hasAttribute('data-audio-tag')
                    && self.playHandles[parseInt(event.target.getAttribute('data-audio-tag'))])
                {
                    console.log('Stopping via tagged handle');
                    self.playHandles[parseInt(event.target.getAttribute('data-audio-tag'))].stop();
                    self.playHandles[parseInt(event.target.getAttribute('data-audio-tag'))] = null;
                }

                if (self.playHandle) {
                    console.log('Stopping via handle');
                    self.playHandle.stop();
                    self.playHandle = null;
                }
                else if (typeof self.player.stop !== 'undefined') {
                    console.log('Stopping via pcuesAudioApi.stop()');
                    self.player.stop();
                }

                self.playLocked = false;
            }
        });

        document.body.addEventListener('click', function(event) {
            let imageAudio = event.target;

            if (imageAudio.hasAttribute('data-audio-text') && imageAudio.hasAttribute('data-play-on-click')) {
                if (imageAudio.hasAttribute('data-title')) {
                    imageAudio.setAttribute('title', imageAudio.getAttribute('data-title'));
                } else {
                    imageAudio.setAttribute('title', imageAudio.getAttribute('data-audio-text'));
                }
            }
        });

    }

    static initializeHoverTooltips() {
        let imageAudios = document.querySelectorAll('.image-audio');
        for (let i = 0; i < imageAudios.length; i++) {
            let imageAudio = imageAudios[i];

            if (imageAudio.hasAttribute('data-audio-text') && imageAudio.hasAttribute('data-play-on-hover')) {
                if (!LocalStorageWrapper.get('hoverState') || LocalStorageWrapper.get('hoverState') === "true") {
                    console.log('Clearing tooltip');
                    imageAudio.setAttribute('title', '');
                } else {
                    if (!imageAudio.hasAttribute('title') || imageAudio.getAttribute('title').length === 0) {
                        if (imageAudio.hasAttribute('data-title')) {
                            imageAudio.setAttribute('title', imageAudio.getAttribute('data-title'));
                        }
                        else {
                            imageAudio.setAttribute('title', imageAudio.getAttribute('data-audio-text'));
                        }
                    }
                }
            }
        }
    };
}

let imgAudioController = null;

if (typeof document !== 'undefined') {
    document.addEventListener("DOMContentLoaded", function() {

        if (typeof pcuesAudioApi !== 'undefined') {
            imgAudioController = new ImageAudioController(pcuesAudioApi);
            imgAudioController.run();
        }
        else {
            console.log('ERROR: pcuesAudioApi not detected.');
        }

        document.body.addEventListener('onPcuesExtensionLoaded', function(event) {
            imgAudioController = new ImageAudioController(pcuesExtensionInterface);
            imgAudioController.run();
        });

    });
}